<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>发票申请</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-merchants-info">
            <span>发票申请</span>
            <div class="sub-merchants-container">
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议名称',pid.cid)}}</div>
                    <el-select
                            v-model="pName"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            @change="proChange"
                            filterable>
                        <el-option
                                v-for="(item, index) in projectOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">所属学会</div>
                    <el-select
                            v-model="society"
                            :placeholder="`请选择所属学会（请选择${$getTitleByType('会议名称',pid.cid)}）`"
                            @change="societyChange"
                            @visible-change="clickProject">
                        <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>

                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议日期',pid.cid)}}</div>
                    <el-input
                            v-model="pid.time"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('大会主席',pid.cid)}}</div>
                    <el-input
                            v-model="pid.chairman"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">上游协议</div>
                    <el-select
                            v-model="agreementName"
                            placeholder="请选择上游协议"
                            @visible-change="clickProject"
                            @change="agreementChange">
                        <el-option
                                v-for="(item,index) in agreementOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">发票类别</div>
                    <el-radio-group v-model="invoice">
                        <el-radio :label="1">赞助</el-radio>
                        <el-radio :label="2">捐赠</el-radio>
                    </el-radio-group>
                </div>
                <approvalList
                        :typeClassify="5"
                        :isNoHave="!id"
                        :approvalMembers="approvalMembers"
                        :copyMembers="copyMembers"
                        @setMember="setMember"
                        @setApproval="setApproval"
                        :society="society"></approvalList>
                <el-button type="primary" @click="subApproval" :loading="requestState" :disabled="requestState" >提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
    import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
    import approvalList from "../../components/approvalList.vue";

    export default {
        components: {
            ElButton,
            ElInput,
            approvalList,
        },
        name: "subMerchants",
        data() {
            return {
                id: "",
                sign_code: "",
                society: "",
                societyOptions: [],
                pid: "",
                pName: "",
                projectOptions: [],
                invoice: 1,
                shenpi: "",
                copy: "",
                approvalMembers: [],
                copyMembers: [],

                agreementName: "",
                agreementId: "",
                agreementOptions: [],

                requestState:false
            };
        },
        created() {
            this.id = this.$route.query.id ? this.$route.query.id : '';
            this.$emit("setIndex", [4, 11]);
            this.init();
        },
        methods: {
            subApproval() {
                if (!this.pid) {
                    this.$message.error("请选择会议名称");
                    return;
                }
                if (!this.society) {
                    this.$message.error("请选择所属学会");
                    return;
                }
                if (!this.agreementId) {
                    this.$message.error("请选择上游协议");
                    return;
                }
                this.requestState = true
                //添加
                this.$api.apiContent
                    .approvalFP({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sid: this.society,
                        pid: this.pid.id,
                        sign_code: this.sign_code,
                        invoice: this.invoice,
                        shenpi: this.shenpi,
                        zid: this.agreementId,
                        copy: this.copy,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                sid: this.society,
                                pid: this.pid.id,
                                sign_code: this.sign_code,
                                invoice: this.invoice,
                                zid: this.agreementId,
                                shenpi: this.shenpi,
                                copy: this.copy,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.requestState = false
                        this.$message.success(res.msg);
                        this.$router.push({
                            name: "invoice",
                            query: {id: res.data.id, type: 1},
                        });
                    })
                    .catch((e) => {
                        this.requestState = false
                        this.$message.error(e.msg);
                    });
            },
            proChange(index) {
                let value = this.projectOptions[index];
                value.time =
                    this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                    " 至 " +
                    this.$tools.ZHDate(new Date(value.end_time * 1000));
                this.pName = value.name;
                this.pid = value;
                this.society = '';
                this.getSociety();
            },
            societyChange(){
                this.agreementName = "";
                this.agreementId = "";
            },
            agreementChange(index){
                let value = this.agreementOptions[index];
                this.agreementId = value.id
                this.agreementName = value.name
                this.invoice = value.categories
            },
            //  获取协议
            getAgreement() {
                this.$api.apiContent
                    .getZanzhuList({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.pid.id,
                        sid: this.society,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: this.pid.id,
                                sid: this.society,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map((item) => {
                            item.name =
                                (item.categories === 1 ? "赞助" : "捐赠") +
                                "(" +
                                item.corporate_name + '/' + item.contacts + '/' + item.corporate_name_remarks +
                                ")/已申请"+item.fapiao_count+'次';
                        });
                        this.agreementOptions = res.data;
                        if (this.agreementId){
                            let item = res.data.filter((item) => {
                                return item.id === this.agreementId;
                            });
                            this.agreementName = item[0].name
                        }
                    })
                    .catch(() => {
                        this.agreementOptions = [];
                    });
            },
            clickProject(view) {
                if (!this.pid && view) {
                    this.$message.error("请选择会议名称");
                }
            },
            getSociety() {
                this.$api.apiContent
                    .getSocietyFromPro({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.societyOptions = res.data;
                        if (res.data.length > 0 && !this.society) this.society = res.data[0].id;
                    })
                    .catch(() => {
                        this.societyOptions = [];
                    });
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .getProject({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map(item => {
                            item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(item.end_time * 1000)) + '/' + item.chairman + '/' + item.name
                        })
                        this.projectOptions = res.data;
                    })
                    .catch(() => {
                        this.projectOptions = [];
                    });
                if (this.id) {
                    this.$api.apiContent
                        .getFapiao({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.id,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: this.id,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then((res) => {
//            this.approvalMembers = res.data.shenpi;
//            this.shenpi = res.data.shenpi
//              .map((item) => {
//                return item.user_id;
//              })
//              .join(",");
                            this.copyMembers = res.data.copy;
                            this.copy = res.data.copy
                                .map((item) => {
                                    return item.user_id;
                                })
                                .join(",");
                            this.society = res.data.sid;
                            this.sign_code = res.data.sign_code;
                            res.data.project.time =
                                this.$tools.ZHDate(
                                    new Date(res.data.project.start_time * 1000)) +
                                " 至 " +
                                this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                            res.data.project.id = res.data.pid;
                            this.pid = res.data.project;
                            // this.invoice = res.data.categories;
                            this.pName = this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000)) + '/' + res.data.project.chairman + '/' + res.data.project.name;
                            this.agreementId = res.data.zid;
                            this.invoice = res.data.invoice;
                            this.getSociety();
                        })
                        .catch(() => {
                        });
                }
            },
            backPage() {
                this.$tools.goBack();
            },
            //审批人
            setApproval(members) {
                this.shenpi = members;
            },
            //抄送人
            setMember(members) {
                this.copy = members;
            },
        },
        watch: {
            society() {
                this.getAgreement();
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }
        .sub-merchants-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            padding: 40px 25px;
            min-height: 800px;
            span {
                color: #000000;
                font-size: 24px;
            }
            .sub-merchants-container {
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                .input-item {
                    display: flex;
                    margin-bottom: 20px;
                    flex-direction: row;
                    align-items: center;
                    overflow: hidden;
                    .input-item-title {
                        width: 20%;
                        text-align: center;
                        font-size: 16px;
                        margin-right: 20px;
                        color: #000000;
                        flex-shrink: 0;
                    }
                    .input-item-desc {
                        width: 20%;
                        align-self: flex-start;
                        margin-top: 10px;
                        margin-right: 20px;
                        text-align: center;
                        font-size: 16px;
                        color: #000000;
                        flex-shrink: 0;
                    }
                    .el-input,
                    .el-select,
                    .el-textarea,
                    .el-radio-group,
                    .el-date-editor,
                    .el-upload {
                        flex-grow: 1;
                        resize: none;
                    }
                }
                > .el-button {
                    margin-top: 30px;
                    align-self: center;
                    width: 40%;
                }
            }
        }
    }
</style>
